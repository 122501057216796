import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "../firebase";
import { ClipLoader } from "react-spinners";

const TodoList = () => {
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [currentTodo, setCurrentTodo] = useState({});
  const [loading, setLoading] = useState(false); // State to track loading

  useEffect(() => {
    fetchTodos();
  }, []);

  const fetchTodos = async () => {
    setLoading(true); // Start loading
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        "https://todo-api.divyangpatel.in/todos",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTodos(response.data);
    } catch (error) {
      toast.error("Error fetching todos", { autoClose: 2000 });
      console.error("Error fetching todos:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const addTodo = async () => {
    if (newTodo.trim() !== "") {
      try {
        const token = await auth.currentUser.getIdToken(true);
        const response = await axios.post(
          "https://todo-api.divyangpatel.in/todos",
          { text: newTodo },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTodos([...todos, response.data]);
        setNewTodo("");
        toast.success("Todo added successfully!", { autoClose: 2000 });
      } catch (error) {
        toast.error("Error adding todo", { autoClose: 2000 });
        console.error("Error adding todo:", error);
      }
    }
  };

  const deleteTodo = async (id) => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      await axios.delete(`https://todo-api.divyangpatel.in/todos/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTodos(todos.filter((todo) => todo._id !== id));
      toast.error("Todo deleted successfully!", { autoClose: 2000 });
    } catch (error) {
      toast.error("Error deleting todo", { autoClose: 2000 });
      console.error("Error deleting todo:", error);
    }
  };

  const editTodo = (todo) => {
    setIsEditing(true);
    setNewTodo(todo.text);
    setCurrentTodo(todo);
  };

  const updateTodo = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.put(
        `https://todo-api.divyangpatel.in/todos/${currentTodo._id}`,
        { text: newTodo, completed: currentTodo.completed },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTodos(
        todos.map((todo) =>
          todo._id === currentTodo._id ? response.data : todo
        )
      );
      setIsEditing(false);
      setNewTodo("");
      setCurrentTodo({});
      toast.warning("Todo updated successfully!", { autoClose: 2000 });
    } catch (error) {
      toast.error("Error updating todo", { autoClose: 2000 });
      console.error("Error updating todo:", error);
    }
  };

  const toggleComplete = async (id) => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      const todo = todos.find((todo) => todo._id === id);
      const response = await axios.put(
        `https://todo-api.divyangpatel.in/todos/${id}`,
        { ...todo, completed: !todo.completed },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTodos(todos.map((todo) => (todo._id === id ? response.data : todo)));
      toast.success("Todo status updated successfully!", { autoClose: 2000 });
    } catch (error) {
      toast.error("Error toggling todo completion", { autoClose: 2000 });
      console.error("Error toggling complete:", error);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-4">To-Do List</h1>
      <div className="flex mb-4">
        <input
          type="text"
          className="border flex-grow py-2 px-3"
          value={newTodo}
          onChange={(e) => setNewTodo(e.target.value)}
          placeholder="Add a new task"
        />
        <button
          className={`${
            isEditing ? "bg-green-500" : "bg-blue-500"
          } text-white py-2 px-4 ml-2`}
          onClick={isEditing ? updateTodo : addTodo}
        >
          {isEditing ? "Update" : "Add"}
        </button>
      </div>
      {loading ? (
        <div className="flex justify-center">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
      ) : (
        <ul>
          {todos.map((todo) => (
            <li
              key={todo._id}
              className="flex justify-between items-center mb-2 border-b pb-2"
            >
              <span
                className={`flex-grow ${todo.completed ? "line-through" : ""}`}
              >
                {todo.text}
              </span>
              <div>
                <button
                  className="bg-green-500 text-white py-1 px-2 mr-2"
                  onClick={() => toggleComplete(todo._id)}
                >
                  {todo.completed ? "Undo" : "Complete"}
                </button>
                <button
                  className="bg-yellow-500 text-white py-1 px-2 mr-2"
                  onClick={() => editTodo(todo)}
                >
                  Edit
                </button>
                <button
                  className="bg-red-500 text-white py-1 px-2"
                  onClick={() => deleteTodo(todo._id)}
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TodoList;
