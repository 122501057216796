// src/components/SignOut.js

import React from "react";
import { auth } from "../firebase";
import { toast } from "react-toastify";

const SignOut = () => {
  const handleSignOut = () => {
    auth.signOut();
    toast.success("User signed out successfully");
  };

  return (
    <button
      onClick={handleSignOut}
      className="bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-2 px-4 rounded-lg shadow-lg hover:from-purple-600 hover:to-indigo-600 focus:outline-none focus:ring-2 focus:ring-purple-300 focus:ring-opacity-50 transform transition-transform duration-200 ease-in-out hover:scale-105"
    >
      Sign Out
    </button>
  );
};

export default SignOut;
