// src/components/GoogleSignIn.js

import React from "react";
import { auth, googleProvider } from "../firebase";
import { signInWithPopup } from "firebase/auth";
import { toast } from "react-toastify";

const GoogleSignIn = () => {
  const handleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      toast.success("User signed in with Google");
      // You can redirect the user to the main app or handle post-login logic here
    } catch (error) {
      toast.error("Error signing in with Google: " + error.message);
    }
  };

  return (
    <button
      onClick={handleSignIn}
      className="flex items-center justify-center border border-gray-300 rounded-lg shadow-sm hover:shadow-md py-2 px-4 bg-white"
    >
      <img
        src="https://img.icons8.com/?size=100&id=17949&format=png&color=000000"
        alt="Google Logo"
        className="w-6 h-6 mr-2"
      />
      <span className="text-gray-700 font-medium">Sign in with Google</span>
    </button>
  );
};

export default GoogleSignIn;
