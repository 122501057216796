// src/components/Home.js

import React, { useState } from "react";
import { motion } from "framer-motion";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import GoogleSignIn from "./GoogleSignIn";

const Home = () => {
  const [isSignIn, setIsSignIn] = useState(true);

  const toggleForm = () => {
    setIsSignIn(!isSignIn);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl font-bold mb-8">TO-DO WORLD ✍️</h1>
      <div className="bg-white p-6 rounded-lg shadow-md w-80">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {isSignIn ? <SignIn /> : <SignUp />}
        </motion.div>
        <button
          onClick={toggleForm}
          className="mt-4 text-blue-500 underline focus:outline-none"
        >
          {isSignIn
            ? "Don't have an account? Sign Up"
            : "Already have an account? Sign In"}
        </button>
      </div>
      <div className="mt-8 w-80">
        <div className="flex items-center">
          <hr className="flex-grow border-gray-300" />
          <span className="mx-4 text-gray-500">OR LOGIN WITH GOOLE</span>
          <hr className="flex-grow border-gray-300" />
        </div>
      </div>
      <div className="mt-4">
        <GoogleSignIn />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Home;
