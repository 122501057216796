import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA5qseoo15fPDELrqjH3he05UsY-KVu6Hk",
  authDomain: "to-do-130c6.firebaseapp.com",
  projectId: "to-do-130c6",
  storageBucket: "to-do-130c6.appspot.com",
  messagingSenderId: "149220916446",
  appId: "1:149220916446:web:53498be318dc771ce3564f",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export default app;
