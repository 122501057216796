// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TodoList from "./components/TodoList";
import SignUp from "./components/SignUp";
import SignIn from "./components/SignIn";
import GoogleSignIn from "./components/GoogleSignIn";
import SignOut from "./components/SignOut";
import { auth } from "./firebase";
import Home from "./components/Home";

const App = () => {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <div className="App">
        {user ? (
          <>
            <SignOut />
            <TodoList />
          </>
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/google-signin" element={<GoogleSignIn />} />
          </Routes>
        )}
      </div>
    </Router>
  );
};

export default App;
